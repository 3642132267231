@media screen and (max-width: 800px) {
  .slide-indicator {
    display: none;
  }

  .slide-feature-textlg {
    top: 5px;
  }

  .slide-feature-textsm {
    top: 33px;
  }

  #header {
    flex-direction: column;
  }

  #header .slogan {
    top: 10px !important;
    right: 0px;
    text-transform: lowercase;
    color: #ec008c;
    font-size: 20px;
    /* width: 65px; */
    /* line-height: 18px; */
    height: 20px;
    display: flex;
    position: absolute;
    justify-content: right;
    margin-left: auto;
    width: auto;
  }

  .main-nav-mobile-buttons, .main-navigation  {
    display: flex;
    flex-direction: row;
    /* justify-content: right; */
    justify-content: flex-end;
    margin: 0;
    margin-top: 25px;
  }

  .main-navigation-mobile, #regular-nav {
    margin-left: auto;
    margin-right: 10px;
    margin-top: -30px;
    width: 100%;
  }

  .main-nav-mobile {
    font-size: 20px;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mobile-menubar {
    padding: 10px;
    width: 45px;
    height: 45px;
    justify-content: center;
    display: flex;
    box-sizing: border-box;
    cursor: pointer;
    margin: 0px !important;
  }

  .mobile-navigation {
    width: 100%;
    display: flex;
    background-color: #ec008c;
    position: relative;
    margin: 0;
    margin: 0;
    justify-content: space-around;
    padding: 7px;
    height: auto;
    font-family: 'neuzeitfcregular', arial, sans-serif;
  }

  .mobile-menubar > i {
    font-size: 20px;
    margin: 0;
  }

  .regilar-nav {
    display: flex;
    flex-direction: column;
  }

  .mobile-menubar > .mobile-nav-search > i {
    font-size: 20px;
    margin: 0;
  }

  .mobile-hamburger, .mobile-menubar {
    background-color: #ec068e;
    color: white;
  }

  .mobile-search, .fa-search {
    background-color: #5e2351;
    color: white !important;
    justify-content: center;
    display: flex;
  }

  .mobile-nav-search.open {
    left: 0;
    width: 92% !important;
  }

  .mobile-nav-search {
    margin: 0 !important;
    display: flex;
  }

  .mobile-search-event {
    display: inline-block;
    width: 100%;
  }

  .mobile-nav-search:not(.open) {
    width: 45px;
    transition: width .5s ease-in-out;
  }

  .mobile-nav-search.open input {
    height: 45px;
    width: 100%;
    box-sizing: border-box;
  }

  .search-input:focus {
    width: calc(100% + 100px) !important;
  }

  .mobile-nav-search {
      -webkit-transition: all 0.30s ease-in-out;
      -moz-transition: all 0.30s ease-in-out;
      -ms-transition: all 0.30s ease-in-out;
      -o-transition: all 0.30s ease-in-out;
  }

  .mobile-sub-nav-container, .sub-nav-container {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    padding: 5px; 
    width: 225px;
    padding: 5px 0;
    border: solid 1px #aaa;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0,0,0,.3);
    color: #565454;
    cursor: pointer;
  }

  .mobile-sub-nav-body {
    padding: 0;
    margin: 0;
    margin-right: auto;
  }

  .mobile-sub-nav-header {
    display: flex;
    margin-left: 10px;
    height: 25px;
    line-height: 1.5;
  }

  .mobile-sub-nav-option {
    margin-right: 0;
    margin-left: 0;
  }

  .mobile-icon-placeholder {
    width: 18px;
    margin-right: 14px;
  }

  .mobile-whatson-contact-info {
    color: #9b9d9f;
    cursor: pointer;
    margin-top: 15px;
  }

  .listing-container-mobile {
    margin-left: auto;
    margin-right: auto;
  }

  .listings-container {
    width: 100%;
  }

  .listing-container {
    min-width: 290px;
    height: 340px;
    margin: 8px;
  }

  .listing-img {
    height: 190px;
  }

  .property-main  {
    flex-wrap: wrap;
  }

  .property-main-left, .property-main-right {
    width: 100%;
    border: 0px;
    margin: 0;
  }

  .property-email-container {
    margin-right: auto;
  }

  .info-block-inner {
    display: flex;
    justify-content: center;
  }

  .agent-main-img {
    width: 240px;
    height: 300px;
  }

  .agent-main-info {
    margin-left: 20px;
  }

  .footer .about-container {
    width: 100%
  }

  .footer .social-container {
    margin-left: auto;
  }

  .mobile-nav-search {
    /* width: auto; */
  }

  #header .slogan {
    justify-content: flex-end;
  }

  .react-slideshow-wrapper.slide {
    max-height: 600px;
  }

  .slide-img {
    min-height: 322px;
    max-height: 400px;
  }

  .contactus-right {
    margin-top: 0px;
  }

  .contactus-inputs {
    flex-direction: column;
  }

  .contactus-input {
    margin-left: 0px;
    width: 95%;
  }

  .rea-container {
    width: 85%;
  }

  .rentals-container .row {
    flex-direction: column;
  }

  .rentals-sub {
    margin-bottom: 25px;
  }

  .rentals-multi-line {
    display: none;
  }

  .rentals-one-line {
    display: block;
  }

  /* Promotion page */

    .promotion-middle-images-container {
      flex-flow: row  wrap;
    
    }


.promotion-header-image,
.promotion-bottom-image {
  /* height: 500px; */
}
  

.promotion-middle-image {
  max-height: 300px;
}

.promotion-top-text-container h1, .promotion-bottom-text-container h2 {
  font-size: 24px;
}






}