@import "../../../node_modules/react-slideshow-image/dist/styles.css";
@font-face {
  font-family: "neuzeitfcregular";
  src: local("neuzeitfcregular"),
    url("../../static/fonts/neuzeitfc-regular-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "neuzeitfclight";
  src: local("neuzeitfclight"),
    url("../../static/fonts/neuzeitfc-light-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "neuzeitfcdemibold";
  src: local("neuzeitfcdemibold"),
    url("../../static/fonts/neuzeitfc-demibold-webfont.ttf") format("truetype");
}

#propertyinfo {
  display: none;
}

html,
body,
#root,
.App,
#wrap {
  /* .home-container  */
  height: auto;
}

.home-container,
.main-body {
  margin-bottom: 20px;
}

.App {
  font-size: 1em;
  line-height: 1.4;
}

#wrap,
#printwrap {
  width: 100%;
  margin: 0 auto;
}

#wrap {
  max-width: 1280px;
}

.mainwrap {
  background-color: #fff;
}

.uppercase {
  text-transform: uppercase;
}

.row {
  display: flex;
  flex-direction: row;
}

.wrap {
  flex-wrap: wrap;
}

.hidden {
  display: none !important;
}

body {
  background-color: #e3e4e6;
  overflow-x: hidden;
  margin: 0;
  font-family: "neuzeitfclight", "neuzeitfcregular", arial, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}

.fullwidth {
  width: 100%;
}

.link-disabled {
  pointer-events: none !important;
}

h1 {
  margin-top: 18px;
  margin-bottom: 18px;
}

h2 {
  margin: 0;
}

.bold {
  font-family: "neuzeitfcdemibold";
}

.main-text {
  width: 95%;
  margin-left: auto !important;
}

.half-width {
  display: none;
  width: 50%;
}

.center-text {
  margin: 0;
  text-align: center;
  justify-content: center;
  align-content: center;
}

.center-item {
  margin-left: auto;
  margin-right: auto;
}

.main-header {
  background-color: white;
}

.main-page-header {
  color: #5e2351;
  text-transform: uppercase;
  font-weight: 800;
  width: 95%;
  margin-left: auto;
  font-size: 32px;
  font-family: "neuzeitfcdemibold", arial, sans-serif;
}

.main-page-subheader,
.main-page-dated-subheader {
  color: #5e2351;
  font-weight: 800;
  font-size: 24px;
  border-bottom: 1px solid #5e2351;
  margin-bottom: 9px;
}

.main-page-subheader {
  font-family: "neuzeitfcdemibold", arial, sans-serif;
  width: 95%;
}

.main-page-dated-subheader {
  font-family: "neuzeitfclight", arial, sans-serif;
  width: 100%;
}

.dated-header {
  margin-bottom: -20px !important;
}

.listing-container {
  min-width: 270px;
  max-width: 270px;
  height: 320px;
  margin: 5px 5px;
  display: inline-block;
  background-color: #efeff0;
}

.listings-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.dated-listings-container {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

@media screen and (max-width: 1255px) {
  .listings-container {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 1135px) {
  .listing-container {
    min-width: 220px;
    max-width: 220px;
  }
}

/* @media screen and (max-width: 925px) {
  .listing-container {
    min-width: 160px;
    max-width: 160px;
  }
} */

.listing-content {
  width: 100%;
}

.listing-img {
  position: relative;
  min-width: 100%;
  max-width: 270px;
  height: 160px;
  background-color: #333;
  overflow: hidden;
}

.listing-info-container {
  padding: 20px;
}

.listing-text-container {
  color: #5e2351;
  margin-bottom: 20px;
}

.listing-main-text {
  text-transform: uppercase;
  /* font-size: 16px; */
  font-size: 1em;
  white-space: nowrap;
  font-family: "neuzeitfcdemibold", arial, sans-serif;
}

.listing-sub-text {
  font-size: 13px;
  min-height: 36px;
  overflow: hidden;
  max-height: 36px;
  text-overflow: ellipsis;
  font-family: "neuzeitfcdemibold", arial, sans-serif;
}

.listing-feature-container {
  width: 35px;
  min-width: 35px;
  max-width: 35px;
}

.listing-feature {
  width: 35px;
  height: 35px;
  position: relative;
  color: #5e2351;
  margin-right: 4px;
}

.listing-feature-border {
  fill: transparent;
  stroke: #5e2351;
  stroke-width: 7px;
}

.listing-feature-text,
.whatson-listing-feature-text {
  font-size: 13px;
  position: absolute;
  top: 5.5px;
  right: 4px;
  left: 4px;
  text-align: center;
  font-family: "neuzeitfcdemibold";
}

/* .whatson-listing-feature-text {
  top: 5.5px;
} */

.listing-feature-icon {
  margin-top: -4px;
  font-size: 12px;
  height: 11px;
  image-rendering: -webkit-optimize-contrast;
}

.print-option-container {
  color: #ec008c;
  display: flex;
  justify-content: flex-end;
  margin-right: 50%;
  width: 95%;
}

.print-text {
  color: #ec008c;
  margin-left: 5px;
}

.property-email-container {
  display: flex;
  justify-content: space-evenly;
  margin-left: auto;
  width: 360px;
}

.property-links {
  color: #ec008c;
  font-size: 16px;
  font-family: "neuzeitfcregular", arial, sans-serif;
  cursor: pointer;
}

.property-sold-sm {
  position: absolute;
  top: 8px;
  /* left: 50px; */
  color: #ffffff;
  text-transform: lowercase;
  font-size: 30px;
  z-index: 2;
  text-align: center;
  width: 100px;
  font-size: 20px;
}

.property-rented-sm {
  position: absolute;
  top: 8px;
  /* left: 50px; */
  color: #ffffff;
  text-transform: lowercase;
  font-size: 30px;
  z-index: 2;
  text-align: center;
  width: 100px;
  font-size: 16px;
}
.property-sold-lg {
  position: absolute;
  top: 30px;
  color: #ffffff;
  text-transform: lowercase;
  z-index: 2;
  text-align: center;
  width: 200px;
  font-size: 28px;
}

.property-sold-lg.triangle-rental {
  top: 24px;
}
svg:not(:root) {
  overflow: hidden;
}

.image-triangle {
  position: absolute;
  top: 0;
  opacity: 0.5;
  fill: #ec008c;
  z-index: 1;
  /* left: 14px; */
}

.triangle-sm {
  width: 100px;
  height: 56px;
}

.triangle-lg {
  width: 200px;
  height: 106px;
}

#logo #logo-lg {
  display: inline;
  margin: 0px 0px 0px 40px;
}

#logo img {
  margin-left: 40px;
  width: 200px;
  padding-top: 10px;
}

#logo-lg img {
  width: 250px;
  padding-top: 10px;
}

#header .slogan {
  /* position: absolute; */
  top: 25px;
  right: 25px;
  left: -3%;
  text-transform: lowercase;
  color: #ec008c;
  font-size: 20px;
  width: 65px;
  line-height: 18px;
  position: relative;
  height: 20px;
}

.main-header-lg {
  margin-top: 25px;
}

nav ul li {
  margin: 0px 6%;
}

.navigation {
  position: relative;
}

.main-navigation {
  line-height: 5;
}

.nav-search .fa-search {
  color: #ec008c;
  margin-right: 5px;
}

.nav-search {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.search-input {
  height: 45px;
  border: none;
  width: 255px;
}

nav ul.navigation > li > a:hover,
nav ul.navigation > li > a:active,
nav ul.navigation > li > a:focus,
nav ul.navigation > li > .selected {
  padding-top: 3px;
  border-top: 2px #ec008c solid;
}

.nav-search:not(.open) {
  width: 150px;
  transition: width 0.5s ease-in-out;
}

.nav-search.open {
  position: absolute;
  background-color: white;
  left: 18px;
  right: -170px;
  width: calc(100% + 150px) !important;
  transition: width 0.5s ease-in-out;
}

/* .nav-search.open > input {
  width: calc(100% + 150px) !important;
} */

nav,
nav ul,
nav ul li,
nav ul li form {
  display: inline;
  white-space: nowrap;
  /* margin-left: 20px; */
  margin-right: auto;
}

#pagination-container {
  line-height: 2.5;
  margin: 20px auto 0px auto;
  display: flex;
  justify-content: center;
}

.main-pagination-text {
  margin: 0px 15px 0px 15px;
  color: rgb(94, 35, 81);
  font-size: 20px;
}

.search-input {
  font-family: "neuzeitfclight", "neuzeitfcregular", arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.search-input::placeholder {
  /* margin-left: 20px;
    padding-left: 20px; */
}

.main-nav {
  color: #9b9d9f;
}

.main-nav,
.search-input,
.search-input::-moz-focus-inner {
  color: #9b9d9f;
  text-decoration: none;
  padding-top: 3px;
  font-size: 16px;
  border: 0px;
  height: 40px;
  cursor: pointer;
}

.sub-nav-container {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 450px;
  flex-wrap: wrap;
}

.sub-nav-container {
  line-height: 0;
}

.sub-nav {
  /* margin-left: 45px; */
}

/* .nav-search.open input {
  width: 100%;
  /* margin-left: 20px; */

/* } */

/* .nav-search input {
  -moz-transition: all 0.30s ease-in-out;
} */

.search-input {
  -moz-transition: all 0.3s ease-in-out;
  width: 100%;
}

.nav-search.open .search-event {
  display: inline-block;
}

.search-event {
  display: inline-block;
}

.search-input:focus {
  /* width: 100%; */
  outline: none;
  border: 1px solid #ec008c;
  box-shadow: 0 0 10px #ec008c;
  width: calc(100% + 250px);
}

.search-input::placeholder {
  /* color: #9b9d9f; */
  opacity: 1;
}

.slideshow-container {
  width: 100%;
}

.slideshow-info-container {
  position: relative;
  display: block;
  margin-top: 25px;
  background-color: #efeff0;
  padding: 10px 20px;
}

.slide-container {
  margin-bottom: 50px;
}

.each-slide-img {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 640px;
}

.slide-indicator {
}

.slide-indicator-true {
  background-color: white;
  height: 15px;
  width: 15px;
  border-radius: 80%;
  margin-right: 15px;
}

.slide-indicator-true:active,
.slide-indicator-true.active {
  background-color: #ec008c;
}

.slide-indicator-false {
  display: none;
}

.indicators {
  margin-top: -40px !important;
  z-index: 2;
  position: relative;
  cursor: pointer;
}

.each-slide {
  min-height: 200px;
  max-height: 650px;
  height: 100%;
  object-fit: contain;
}

.slide-img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  /* height: auto; */
}

.slide-text-container {
  position: absolute;
  display: block;
  bottom: 150px;
  padding-left: 85px;
}

.slide-textlg,
.slide-textsm {
  color: #ffffff;
  text-shadow: 2px 2px 2px black;
}

.slide-textlg {
  font-size: 3em;
  text-transform: uppercase;
  font-family: "neuzeitfcdemibold", arial, sans-serif;
}

.slide-textsm {
  font-size: 1em;
  margin: 0px 0px 5px 5px;
}

.slide-area-text {
}

.slide-feature-container {
  display: flex;
  flex-direction: row;
}

.slide-feature {
  color: #ffffff;
  width: 60px;
  height: 60px;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
}

.feature-border {
  fill: #000000;
  fill-opacity: 0.2;
  stroke: #ffffff;
  stroke-width: 4px;
}

.slide-feature-textlg {
  position: absolute;
  top: 7px;
  /* right: 24px; */
  font-size: 30px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.slide-feature-textsm {
  position: absolute;
  font-size: 11px;
  top: 41px;
  right: 4px;
  left: 4px;
  text-transform: lowercase;
  font-weight: bold;
  text-align: center;
}

.footer {
  background-color: #5e2351;
  color: #fff;
  width: calc(100% - 40px);
  min-height: 200px;
  /* margin-top: 70px; */
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 10px;
}

.footer .about-container {
  width: 60%;
  margin: 0px 3%;
  min-width: 300px;
}

.footer .footer-header {
  text-transform: uppercase;
  font-family: "neuzeitfcregular";
  margin: 15px 0px;
}

.footer .company-container,
.footer .social-container {
  display: flex;
  flex-direction: column;
  margin: 0px 3%;
}

.footer .social-container {
  margin-top: 10px;
}

.footer .about-text,
.footer .link {
  font-size: 13px;
  text-decoration: none;
  color: white;
  line-height: 1.6;
}

.link {
  cursor: pointer;
}

.footer .fa {
  font-size: 40px;
  display: block;
  padding: 6px 0;
  color: #fff;
  transform: translate(0, 0);
  text-decoration: none;
}

button {
  cursor: pointer;
}

.property-header {
  color: #5e2351;
  text-transform: uppercase;
  width: 94%;
  font-size: 30px;
  margin-bottom: 8px;
  margin-top: 8px;
  font-family: "neuzeitfcdemibold";
}

.property-subheader {
  color: #5e2351;
  font-family: "neuzeitfcdemibold";
  /* font-weight: 800; */
  width: 94%;
  font-size: 26px;
}

.property-subheader-sm {
  font-size: 18px;
  /* font-weight: 800; */
  font-family: "neuzeitfcdemibold";
  margin-top: 5px;
}

.property-count-container {
  margin-top: 15px;
  margin-bottom: 10px;
}

.slide-feature-container,
.property-count-container {
  image-rendering: -webkit-optimize-contrast;
}

.property-feature,
.property-print-feature {
  width: 45px;
  height: 45px;
  position: relative;
  color: #5e2351;
  margin-right: 4px;
}

.property-print-feature {
  font-family: "neuzeitfcdemibold";
  display: flex;
  color: #870052;
  justify-content: space-between;
  margin-top: 15px;
}

.property-feature-border {
  fill: transparent;
  stroke: #5e2351;
  stroke-width: 3.5px;
}

.property-feature-text {
  font-size: 20px;
  position: absolute;
  top: 5px;
  right: 4px;
  left: 4px;
  text-align: center;
}

.property-feature-icon {
  margin-top: -6px;
  font-size: 13px;
}

.property-main {
  margin-top: 40px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
}

.property-container {
  width: 100%;
}

.property-main-left {
  border-right: 1px solid #ec008c;
  padding-right: 30px;
  margin-left: 20px;
  width: 60%;
}

.property-main-right {
  margin-left: 45px;
  margin-right: 20px;
  width: 40%;
}

.property-info-block {
  /* margin-top: 35px; */
  padding-bottom: 20px;
  border-bottom: 1px solid #ec008c;
  width: 100%;
}

.property-info-block:empty {
  display: none;
}


.property-main-text {
  white-space: break-spaces;
  padding-bottom: 40px;
}

.property-agent-img {
  width: 120px;
  height: 160px;
}

.property-agents {
  margin-top: 25px;
}

.social-sharing-container {
  /* justify-content: right; */
  justify-content: flex-end;
  margin-right: 20px;
  margin-top: -118px;
  position: relative;
}

.social-share-btn {
  margin: 4px;
}

.info-block-lastitem {
  border-bottom: 0px !important;
}

.info-block-header {
  font-weight: bold;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.info-list-item {
  /* list-style-type: circle; */
  list-style-type: disc;
}

.info-block-text {
  font-size: 16px;
}

.auction-block-text {
  /* margin-top: -18px; */
}

.info-block-text-sm {
  font-size: 14px;
  margin-top: 16px;
}

.property-web-id-container {
  margin-top: 20px;
}

.property-web-id {
  font-weight: 600;
  color: #ec008c;
  margin-right: 5px;
}

.property-map-container {
  height: 260px;
  width: 100%;
}

.agent-listing {
  margin: 5px;
}

.agent-listing-container {
  padding: 20px;
}

.agent-img {
  cursor: pointer;
  object-fit: cover;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.agent-img:hover {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
}

.agent-thumbnail-img {
  width: 220px;
  height: 260px;
}

.agent-listing-details {
  background-color: #efeff0;
  padding: 20px;
  margin-top: -6px;
}

.agent-listing-name {
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 16px;
  font-family: "neuzeitfclight", "neuzeitfcregular", arial, sans-serif;
}

.agent-listing-number-header {
  /* text-decoration: underline; */
  border-bottom: 2px double;
  margin-right: 10px;
}

.agent-listing-numbers {
  margin-bottom: 20px;
  line-height: 20px;
}

.lighttext {
  color: #9b9d9f;
  text-decoration: none;
  cursor: pointer;
}

.lighttext-highlight {
  font-weight: bold;
  color: #5e2351;
}

.pdf-icon {
  margin-right: 5px;
}

.agent-info {
  width: 55%;
  margin-top: -18px;
}

.agent-main {
  margin-left: 4%;
}

.agent-main-img {
  width: 240px;
  /* height: 370px; */
  height: auto;
}

.agent-main-info {
  width: 95%;
  margin-left: auto;
}

.whatson-container {
  margin-top: 60px;
  margin-bottom: 60px;
}

.whatson-inner {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-content: center;
  display: flex;
  margin-top: 22px;
  width: 100%;
}

.whatson-inner .sub-nav-container {
  width: 320px;
}

.whatson-listings-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.whatson-item {
  height: 320px;
}

#openhome-transition,
#auction-transition,
#newlisting-transition {
  position: relative;
  left: 0;
  transition: 1s;
}

.transition-slide-inner {
  position: relative;
  overflow: hidden;
  /* width: 100%; */
  max-width: 100%;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

.transition-slide-outer {
  /* width: 100%; */
  overflow: hidden;
}

.whatson-item-small {
  width: 184px;
  min-width: 184px;
  max-width: 184px;
  margin-left: 5px;
  margin-right: 5px;
}

.whatson-item-large {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 9px;
}

.whatson-listing-info-container {
  padding: 20px;
  background-color: #efeff0;
  box-sizing: border-box;
  text-align: left;
  height: 160px;
}

.image-corner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  bottom: 0px;
  opacity: 0.5;
}

.image-corner:hover {
  fill: #ec008c;
}

.sale-method {
  position: absolute;
  bottom: 20px;
  right: 10px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 20px;
}

.whatson-listing-img-container {
  position: relative;
  width: 100%;
  height: 160px;
  background-color: #333;
  overflow: hidden;
}

.whatson-listing-img-container:hover {
  background-color: pink;
}

.whatson-listing-content {
  width: 100%;
  cursor: pointer;
}

.whatson-listing-img {
  width: 100%;
  object-fit: cover;
  position: relative;
  min-width: 100%;
  background-color: #333;
  overflow: hidden;
  cursor: pointer;
  height: 160px;
}

.greyscale-img {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.greyscale-img:hover {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
}

.whatson-listing-times {
  font-size: 12px;
  color: gray;
  font-weight: 500;
  text-align: left;
}

.whatson-date-item {
  height: 100%;
  color: white;
  background: radial-gradient(
    ellipse at center,
    rgba(102, 102, 102, 1) 0%,
    rgba(51, 51, 51, 1) 100%
  );
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 184px;
  margin-left: 5px;
  margin-right: 5px;
}

.whatson-date-sm {
  font-size: 26px;
  font-family: "neuzeitfcdemibold";
}

.whatson-date-lg {
  font-size: 60px;
  font-family: "neuzeitfcdemibold";
}

.whatson-date-text {
  padding: 10px;
  margin-top: 30px;
}

.whatson-prevnext-icon {
  color: #b3b3b3;
  cursor: pointer;
  font-size: 35px;
  margin-top: 140px;
  margin-right: 5px;
  margin-left: 5px;
}

.whatson-prevnext-icon:hover {
  color: #828282;
}

.prevnext-icon-disabled,
.prevnext-con-disabled:hover {
  color: #e6e6e6;
  cursor: pointer;
  pointer-events: none;
}

.whatson-contact-row,
.mobile-whatson-contact-row {
  justify-content: space-between;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}

.mobile-whatson-contact-row,
#mobile-contact-row {
  display: flex;
  flex-direction: column;
}

.whatson-contact-info {
  color: #9b9d9f;
  cursor: pointer;
}

.whatson-viewall {
  color: rgb(94, 35, 81);
  border: 2px solid #cccccc;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 9px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  width: 95%;
  line-height: 1.4;
}

.whatson-text {
  color: rgb(94, 35, 81);
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.contactus-container {
  display: flex;
  flex-direction: row;
}

.contactus-form {
  margin-left: 70px;
}

.contactus-left {
  padding-right: 30px;
  width: 50%;
}

.contactus-p {
  margin: 0;
  font-size: 14px;
}

.contactus-inputs {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.contactus-kre {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 30vw;
}

.emailto-inputs {
  display: flex;
  justify-content: space-between;
}

.contactus-input {
  width: 60%;
  border: 1px solid black;
  min-width: 60%;
  box-sizing: border-box;
  margin-left: 15px;
}

.contactus-inputs > input {
  max-height: 24px;
}

.emailto-input {
  width: 260px;
  border: 1px solid black;
  margin-left: 10px;
}

.emailto-emergencyph {
  width: 94%;
  margin-left: auto;
  margin-top: 15px;
}

.contactus-form-btn {
  width: 150px;
  min-width: 100px;
  border-radius: 20px;
  transition: all 300ms ease-in-out;
  background-color: #ec008c;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  padding: 12px;
  text-align: center;
  font-weight: bold;
  line-height: 1;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

.contactus-reset {
  background-color: #5e2351;
}

.contactus-submit-container {
  margin-bottom: 20px;
}

.contactus-submit-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}

.contactus-right {
  /* padding-left: 4%;
  margin-left: 10px;
  border-left: 1px solid #ec008c;
  margin-top: -80px; */
  margin-bottom: 20px;
  max-height: 300px;
}

.contact-details {
  margin: 0;
  font-size: 14px;
}

.contactus-input-true {
  border: 1px solid red;
}

.contactus-subheader {
  font-size: 28px;
  margin-top: 30px;
}

.contactus-subheader-sm {
  font-size: 16px;
}

.print-view-container {
  width: 50%;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}

.print-title {
  color: #870052;
  font-family: "neuzeitfcdemibold";
  /* font-weight: 800; */
  font-size: 14px;
}

.print-page-left {
  width: 55%;
  min-width: 250px;
  margin-right: 20px;
}

.print-page-right {
  margin-left: auto;
}

.print-subheader {
  border-bottom: 1px solid #870052;
}

.feature-print-icon {
  height: 11px;
  margin-right: 5px;
  line-height: 1;
}

.feature-print-text {
  font-size: 13px;
  margin-right: 5px;
}

.print-body-text {
  font-size: 11px;
  margin-top: 5px;
}

.print-body-text > ul {
  padding-left: 20px;
}

.print-agent-item {
  margin-top: 10px;
  margin-bottom: 20px;
}

.print-page-disclaimer {
  font-size: 9px;
  font-style: italic;
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  /* font-family: 'neuzeitfcregular', arial, sans-serif; */
}

.print-page-image {
  width: 240px;
  margin-bottom: 10px;
}

.print-page-image-sm {
  width: 150px;
}

.print-row-item {
  width: 33%;
  padding-top: 10px;
  font-size: 12px;
}

.print-listing-title {
  font-size: 18px;
  color: #870052;
}

.print-listing-date {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  font-size: 12px;
}

.privacy-text {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.center {
  text-align: center;
  justify-content: center;
}

.notfound-text {
  font-size: 24px;
}

.notfound-img {
  width: 150px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
}

.notfound-link {
  color: #a5a5a5;
  font-size: 17px;
  font-family: "neuzeitfclight";
}

.disclaimer-left {
  width: 95%;
  margin-left: auto;
}

.rea-container {
  width: 50%;
}

.cursor {
  cursor: pointer;
}


/* rentals page */

.rentals-container {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  color: #5e2351;
  margin-top: 40px;
  margin-bottom: 18px;
  min-height: calc(100vh - 404px);
  flex-direction: column;
  display: flex;
}

.rentals-container .row {
  margin-bottom: 100px;
}

.rentals-sub {
  min-width: 33%;
  flex-grow: 1;
  margin-right: 25px;
}

 .rentals-multi-line {
   display: block;
 }

 .rentals-one-line {
   display: none;
 }

.rentals-sub h1 {
}

.rentals-p,
.rentals-container a {
  font-family: "neuzeitfcdemibold";
  margin: 0;
  color: #5e2351;
}

.logos-container {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  align-items: flex-end;
  margin-bottom: 50px;
  justify-content: space-between;
  gap: 20px;
}

.logos-container .logo-container {

}

.logos-container .logo-container img {

}

/* Promotion page */

.promotion-container {

}

.promotion-header-image-container, .promotion-top-text-container, .promotion-middle-images-container, .promotion-bottom-image-container {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
   /* margin-top: 20px; */
  padding-bottom: 20px;
  padding-top: 20px;

}

.promotion-header-image-container {
  margin-bottom: 0;

}

.promotion-header-image, .promotion-bottom-image {
  width: 100%;
  object-fit: cover;
}

.promotion-top-text-container {
    padding-left: 40px;
    padding-right: 40px;
  margin-bottom: 0;
}

.promotion-top-text-container .about-text {
  margin: 0;
}


.promotion-top-text-container .about-text *:last-child {
  margin-bottom: 0;
}


.promotion-top-text-container h1 {
  margin-top: 0px;
  color: #5e2351;
  font-family: "neuzeitfcdemibold", arial, sans-serif;
  text-align: center;
}

.promotion-middle-images-container {
  display: flex;
  flex-flow: row nowrap;
  /* width: 100%; */
  width: calc(100% - 40px);
  gap: 20px;
}

.ml-image-container {
  width: 100%;
    max-width: 100%;

}

.promotion-middle-image {
width: 100%;
  max-width: 100%;
  max-height: 400px;
  object-fit: cover;
  object-position: 50% 66%;
}

.promotion-bottom-text-container {
    /* background-color: #e2e4e6; */
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 30px;
    padding-top: 40px;

}

.promotion-bottom-text-container .about-text {
  margin: 0;
}

 .promotion-bottom-text-container .about-text *:last-child {
   margin-bottom: 0;
 }

.promotion-bottom-text-container ul {
    list-style-position: outside;
    padding-left: 0;
    margin-left: 15px;
}

.promotion-bottom-text-container ul li {
    margin-bottom: 3px;
}


.promotion-bottom-text-container h2 {
  color: #5e2351;
  font-family: "neuzeitfcdemibold", arial, sans-serif;
}


.promotion-top-text-container a, .promotion-bottom-text-container a {
  text-decoration: underline;
}

.promotion-bottom-image-container {
  margin-bottom: 0px;
  padding-top: 40px;

}

.promotion-bottom-image {

}
.rentals-text-row {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  color: #5e2351;
  margin-top: 18px;
}